import * as types from '../mutation_types';
import router from '@views/home/router';
import axios from 'axios';

const debug = false;
const debugGetFilters = debug;

export default {
  getFilters({commit, state, dispatch, rootState}) {
    return new Promise((resolve, reject) => {
      dispatch('setLoading', true, {root: true});

      let call;
      if (debugGetFilters) {
        call = import('@fixtures/filters-fixture');
      } else {
        const token = router.app.$context.token;
        call = axios.get(`/api/v1/room-filter/`, {
          headers: {
            Authorization: `Token ${token}`
          }
        });
      }
      call.then(response => {
        dispatch('filter/setFilters', response.data, {root: true});
        resolve(response.data);
        dispatch('setLoading', false, {root: true});
      }).catch(err => {
        reject(err);
        dispatch('setLoading', false, {root: true});
      });
    });
  },
  setFilters({commit}, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.SET_FILTERS, payload);
        resolve();
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  },
  toggleActiveFilter({commit}, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.TOGGLE_ACTIVE_FILTER, payload);
        resolve();
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  },
  deleteActiveFilters({commit}) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.DELETE_ACTIVE_FILTERS);
        resolve();
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  }
};
