import * as types from '../mutation_types';

export default {
  [types.SET_FILTERS](state, value) {
    state.filters = value;
  },
  [types.TOGGLE_ACTIVE_FILTER](state, value) {
    const index = state.activeFilters.indexOf(value);
    if (index !== -1) {
      state.activeFilters.splice(index, 1);
    } else {
      state.activeFilters.push(value);
    }
  },
  [types.DELETE_ACTIVE_FILTERS](state) {
    state.activeFilters = [];
  }
}
