/* Router dependencies */
import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@store/index';
import moment from 'moment';

Vue.use(VueRouter);

const debugRouting = true;

const initApp = (to, from, next) => {
  debugRouting && console.log('initApp');

  try {
    // App not ready, call API
    debugRouting && console.log('App not ready, call Init API');
    // Here you can call some api of initialization
    // Here you can set some data to store
    // Set appReady to true
    store.dispatch('setAppReady', true).then(() => {
      debugRouting && console.log('App is ready, Init API called');
      next();
    });
  } catch (e) {
    debugRouting && console.log('Init API error', e);
    // Here you can manage the error
    // Set appReady to true
    store.dispatch('setAppReady', true).then(() => {
      // API error, redirect to homepage
      debugRouting && console.log('App is ready, Init API respond with errors');
      next({name: 'index'});
    });
  }
};

const setup = (to, from, next) => {
  debugRouting && console.log('setup');

  // Here you can call some api that you need to call every time navigation occurs
  // Here you can made some control that you need to verify every time navigation occurs
  let valid = true;
  if (router.app.$context.token) {
    debugRouting && console.log('Verify token');
    debugRouting && console.log('Token in context');
    valid = valid * true;
  } else {
    debugRouting && console.log('Token not in context');
    valid = valid * false;
  }
  // Check only for children routes of Calendar
  const matched = to.matched.find(el => el.name === to.name);
  const isChildrenRoute = matched.parent ? true : false;
  if (to.matched[0].name === 'calendar' && isChildrenRoute) {
    debugRouting && console.log('Verify date');

    if ('date' in to.params) {
      if (moment(to.params.date).isValid()) {
        debugRouting && console.log('Date is valid');
        valid = valid * true;
      } else {
        debugRouting && console.log('Date is not valid');
        valid = valid * false;
      }
    } else {
      debugRouting && console.log('Date not in params');
      valid = valid * false;
    }
  }
  if (valid) {
    next();
  } else {
    next({name: 'error'});
  }
};

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('./pages/index.vue'),
    redirect: () => {
      return {name: 'calendar'};
    }
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import('./pages/calendar.vue'),
    children: [
      {
        path: ':date/rooms',
        name: 'rooms',
        component: () => import('./pages/rooms.vue'),
        children: [
          {
            path: ':plant_id/workstations/:area_slug',
            name: 'workstations',
            component: () => import('./pages/workstations.vue'),
            children: [
              {
                path: ':workstation_id',
                name: 'workstation',
                component: () => import('./pages/workstation.vue')
              }
            ]
          }
        ]
      },
      {
        path: ':date/parking_lot',
        name: 'parking_lot',
        component: () => import('./pages/parking_lot.vue'),
        beforeEnter: (to, from, next) => {
          if (router.app.$context.allowParking) {
            next();
          } else {
            next({name: 'error'});
          }
        },
        children: [
          {
            path: ':parking_space_id',
            name: 'parking_space',
            component: () => import('./pages/parking_space.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/calendar_team',
    name: 'calendar_team',
    component: () => import('./pages/calendar_team.vue'),
    beforeEnter: (to, from, next) => {
      if (store.state.me.is_team_head) {
        next();
      } else {
        next({name: 'calendar'});
      }
    }
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('./pages/error.vue')
  },
  {
    path: '*',
    redirect: {name: 'index'}
  }
];

const router = new VueRouter({
  scrollBehavior() {
    return {x: 0, y: 0};
  },
  routes
  // mode: 'history',
});

router.beforeEach((to, from, next) => {
  if (to.name === 'error') {
    next();
  } else {
    debugRouting && console.log('Check if the App is ready, AppReady:', store.state.appReady);

    if (!store.state.appReady) {
      // Controllo bootstrapper
      if (!store.state.vueBootstrapper.ready) {
        // Bootstrapper non completo mi metto in watch
        store.watch(() => store.state.vueBootstrapper.ready, status => {
          if (status) {
            // Bootstrapper completo, procedo
            initApp(to, from, next);
          }
        });
      } else {
        // Bootstrapper completo, procedo
        initApp(to, from, next);
      }

      store.watch(() => store.state.appReady, status => {
        debugRouting && console.log('appReady value changed, appReady:', status);
        if (status) {
          // App is ready, you can run setup logic
          setup(to, from, next);
        }
      });
    } else {
      // App is ready, Init API already called
      debugRouting && console.log('App is ready, Init API already called');
      // App is ready, you can run setup logic
      setup(to, from, next);
    }
  }
});

export default router;
