import * as types from '../mutation_types';
import router from '@views/home/router';
import axios from 'axios';

const debug = false;
const debugGetWorkstations = debug;

export default {
  getWorkstations({commit, state, dispatch, rootState}, payload) {
    return new Promise((resolve, reject) => {
      dispatch('setLoading', true, {root: true});

      let call;
      if (debugGetWorkstations) {
        call = import('@fixtures/workstations-fixture');
      } else {
        const token = router.app.$context.token;
        call = axios.get(`/api/v1/room-plant/${payload.plant_id}/room/${payload.area_slug}/?device_size=${payload.device_size}&date=${payload.date}`, {
          headers: {
            Authorization: `Token ${token}`
          }
        });
      }
      call.then(response => {
        dispatch('workstations/setWorkstations', response.data, {root: true});
        resolve(response.data);
        dispatch('setLoading', false, {root: true});
      }).catch(err => {
        reject(err);
        dispatch('setLoading', false, {root: true});
      });
    });
  },
  setWorkstations({commit}, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.SET_WORKSTATIONS, payload);
        resolve();
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  }
};
