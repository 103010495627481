import * as types from '../mutation_types';
import axios from 'axios';

const debug = false;
const debugGetWorkstationsConfig = debug;
const debugGetOptionals = debug;

export default {
  getWorkstationsConfig({commit, state, dispatch, rootState}, payload) {
    return new Promise((resolve, reject) => {
      dispatch('setLoading', true, {root: true});

      let call;
      if (debugGetWorkstationsConfig) {
        call = import('@fixtures/workstations-config-fixture');
      } else {
        call = axios.get(`/api/v1/config/room/${payload.area_slug}/workstation/?device_size=${payload.device_size}`);
      }
      call.then(response => {
        dispatch('workstationsConfig/setWorkstationsConfig', response.data, {root: true});
        resolve(response.data);
        dispatch('setLoading', false, {root: true});
      }).catch(err => {
        reject(err);
        dispatch('setLoading', false, {root: true});
      });
    });
  },
  setWorkstationsConfig({commit}, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.SET_WORKSTATIONS_CONFIG, payload);
        resolve();
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  },
  addWorkstation({commit, state, dispatch, rootState}, payload) {
    return new Promise((resolve, reject) => {
      dispatch('setLoading', true, {root: true});

      axios.post(`/api/v1/config/room/${payload.area_slug}/workstation/?device_size=${payload.device_size}`, {position_x: payload.position_x, position_y: payload.position_y}).then(response => {
        resolve(response.data);
        dispatch('setLoading', false, {root: true});
      }).catch(err => {
        reject(err);
        dispatch('setLoading', false, {root: true});
      });
    });
  },
  getOptionals({commit, state, dispatch, rootState}) {
    return new Promise((resolve, reject) => {
      dispatch('setLoading', true, {root: true});

      let call;
      if (debugGetOptionals) {
        call = import('@fixtures/optionals-fixture');
      } else {
        call = axios.get(`/api/v1/config/optionals/`);
      }
      call.then(response => {
        dispatch('workstationsConfig/setOptionals', response.data, {root: true});
        resolve(response.data);
        dispatch('setLoading', false, {root: true});
      }).catch(err => {
        reject(err);
        dispatch('setLoading', false, {root: true});
      });
    });
  },
  setOptionals({commit}, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.SET_OPTIONALS, payload);
        resolve();
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  },
  setActiveOptionals({commit}, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.SET_ACTIVE_OPTIONALS, payload);
        resolve();
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  },
  toggleActiveOptionals({commit}, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.TOGGLE_ACTIVE_OPTIONALS, payload);
        resolve();
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  },
  togglePublished({commit}, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.TOGGLE_PUBLISHED, payload);
        resolve();
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  },
  deleteActiveOptionals({commit}) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.DELETE_ACTIVE_OPTIONALS);
        resolve();
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  },
  updateWorkstationOptionals({commit, state, dispatch, rootState}, payload) {
    return new Promise((resolve, reject) => {
      dispatch('setLoading', true, {root: true});

      axios.put(`/api/v1/config/room/${payload.area_slug}/workstation/${payload.workstation_id}/optional/`, {
        published: payload.published,
        bookable_optionals: payload.bookable_optionals
      }).then(response => {
        resolve(response.data);
        dispatch('setLoading', false, {root: true});
      }).catch(err => {
        reject(err);
        dispatch('setLoading', false, {root: true});
      });
    });
  },
  updateWorkstationPositions({commit, state, dispatch, rootState}, payload) {
    return new Promise((resolve, reject) => {
      dispatch('setLoading', true, {root: true});

      axios.put(`/api/v1/config/room/${payload.area_slug}/workstation/${payload.workstation_id}/?device_size=${payload.device_size}`, {
        position_x: payload.position_x,
        position_y: payload.position_y,
        workstation_width: payload.workstation_width,
        workstation_height: payload.workstation_height
      }).then(response => {
        resolve(response.data);
        dispatch('setLoading', false, {root: true});
      }).catch(err => {
        reject(err);
        dispatch('setLoading', false, {root: true});
      });
    });
  },
  deleteWorkstation({commit, state, dispatch, rootState}, payload) {
    return new Promise((resolve, reject) => {
      dispatch('setLoading', true, {root: true});

      axios.delete(`/api/v1/config/room/${payload.area_slug}/workstation/${payload.workstation_id}/`).then(response => {
        resolve(response.data);
        dispatch('setLoading', false, {root: true});
      }).catch(err => {
        reject(err);
        dispatch('setLoading', false, {root: true});
      });
    });
  }
};
