import * as types from '../mutation_types';
import router from '@views/home/router';
import axios from 'axios';

const debug = false;
const debugGetMe = debug;

export default {
  getMe({commit, state, dispatch, rootState}) {
    return new Promise((resolve, reject) => {
      dispatch('setLoading', true, {root: true});

      let call;
      if (debugGetMe) {
        call = import('@fixtures/me-fixture');
      } else {
        const token = router.app.$context.token;
        call = axios.get('/api/v1/me/', {
          headers: {
            Authorization: `Token ${token}`
          }
        });
      }
      call.then(response => {
        dispatch('me/setMe', response.data, {root: true});
        resolve(response.data);
        dispatch('setLoading', false, {root: true});
      }).catch(err => {
        reject(err);
        dispatch('setLoading', false, {root: true});
      });
    });
  },
  setMe({commit}, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.SET_ME, payload);
        resolve();
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  },
  getMeDailyStatus({commit, state, dispatch, rootState}, payload) {
    return new Promise((resolve, reject) => {
      dispatch('setLoading', true, {root: true});

      let call;
      if (debugGetMe) {
        call = import('@fixtures/me-daily-status-fixture');
      } else {
        const token = router.app.$context.token;
        call = axios.get(`/api/v1/me/daily-status/?date=${payload.date}`, {
          headers: {
            Authorization: `Token ${token}`
          }
        });
      }
      call.then(response => {
        resolve(response.data);
        dispatch('setLoading', false, {root: true});
      }).catch(err => {
        reject(err);
        dispatch('setLoading', false, {root: true});
      });
    });
  }
};
