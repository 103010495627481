<template>
  <div class="Home">
    <main>
      <router-view />
    </main>
    <vue-confirm-dialog class="custom-vue-confirm-dialog" />
    <page-loader-cmp />
  </div>
</template>

<script>
  import PageLoaderCmp from '@components/page-loader-cmp';
  import imagesLoaded from 'imagesloaded';

  export default {
    name: 'Home',
    components: {
      PageLoaderCmp
    },
    mounted() {
      this.$nextTick(() => {
        imagesLoaded(this.$el, {background: true}, () => {
          this.$store.dispatch('setLoading', false);
        });
      });
    }
  };
</script>

<style lang="scss" scoped></style>
