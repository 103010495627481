/* Store initialization */
/* Global */
export const SET_APP_READY = 'SET_APP_READY';
export const SET_LOADING = 'SET_LOADING';
export const SET_UNLOCKED_VIEW = 'SET_UNLOCKED_VIEW';

/* Me */
export const SET_ME = 'SET_ME';

/* Calendar */
export const SET_CALENDAR = 'SET_CALENDAR';

/* Calendar Team */
export const SET_CALENDAR_TEAM = 'SET_CALENDAR_TEAM';

/* Filters */
export const SET_FILTERS = 'SET_FILTERS';
export const TOGGLE_ACTIVE_FILTER = 'TOGGLE_ACTIVE_FILTER';
export const DELETE_ACTIVE_FILTERS = 'DELETE_ACTIVE_FILTERS';

/* Rooms */
export const SET_ROOMS = 'SET_ROOMS';

/* Workstations */
export const SET_WORKSTATIONS = 'SET_WORKSTATIONS';

/* Workstation */
export const SET_WORKSTATION = 'SET_WORKSTATION';

/* Parking lot */
export const SET_PARKING_LOT = 'SET_PARKING_LOT';

/* Parking space */
export const SET_PARKING_SPACE = 'SET_PARKING_SPACE';

/* Workstations config */
export const SET_WORKSTATIONS_CONFIG = 'SET_WORKSTATIONS_CONFIG';
export const SET_OPTIONALS = 'SET_OPTIONALS';
export const SET_ACTIVE_OPTIONALS = 'SET_ACTIVE_OPTIONALS';
export const TOGGLE_ACTIVE_OPTIONALS = 'TOGGLE_ACTIVE_OPTIONALS';
export const TOGGLE_PUBLISHED = 'TOGGLE_PUBLISHED';
export const DELETE_ACTIVE_OPTIONALS = 'DELETE_ACTIVE_OPTIONALS';
