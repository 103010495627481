export default {
  workstations: (state) => {
    return state.workstations;
  },
  optionals: (state) => {
    return state.optionals;
  },
  active_optionals: (state) => {
    return state.active_optionals;
  }
}
