import * as types from '../mutation_types';

export default {
  [types.SET_ME](state, value) {
    state.first_name = value.first_name;
    state.last_name = value.last_name;
    state.is_team_head = value.is_team_head;
    state.team_headed_name = value.team_headed_name;
  }
};
