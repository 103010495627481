/* Mutations, actions and getters */
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    first_name: '',
    last_name: '',
    is_team_head: false,
    team_headed_name: ''
  },
  actions,
  mutations,
  getters
};
