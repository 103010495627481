import { Vue, store } from './common.js';
import Home from '@views/home/home';
import router from '@views/home/router';

new Vue({
  el: '#app-root',
  store: store,
  router,
  components: {Home},
  template: `
    <Home />`
});
