import * as types from '../mutation_types';
import router from '@views/home/router';
import axios from 'axios';

const debug = false;
const debugGetCalendarTeam = debug;

export default {
  getCalendarTeam({commit, state, dispatch, rootState}) {
    return new Promise((resolve, reject) => {
      dispatch('setLoading', true, {root: true});

      let call;
      if (debugGetCalendarTeam) {
        call = import('@fixtures/calendar-team-fixture');
      } else {
        const token = router.app.$context.token;
        call = axios.get('/api/v1/calendar/team-members/', {
          headers: {
            Authorization: `Token ${token}`
          }
        });
      }
      call.then(response => {
        dispatch('calendarTeam/setCalendarTeam', response.data, {root: true});
        resolve(response.data);
        dispatch('setLoading', false, {root: true});
      }).catch(err => {
        reject(err);
        dispatch('setLoading', false, {root: true});
      });
    });
  },
  setCalendarTeam({commit}, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.SET_CALENDAR_TEAM, payload);
        resolve();
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  }
};
