/* Import dependencies */
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

/* Mutations, actions and getters */
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

/* Modules */
import me from './me';
import calendar from './calendar';
import calendarTeam from './calendar-team';
import filter from './filter';
import rooms from './rooms';
import workstations from './workstations';
import workstation from './workstation';
import parkingLot from './parking-lot';
import parkingSpace from './parking-space';
import workstationsConfig from './workstations-config';

export default new Vuex.Store({
  state: {
    appReady: false,
    loading: [true],
    unlockedView: null
  },
  actions,
  mutations,
  getters,
  modules: {
    me,
    calendar,
    calendarTeam,
    filter,
    rooms,
    workstations,
    workstation,
    parkingLot,
    parkingSpace,
    workstationsConfig
  }
});
