import * as types from '../mutation_types';

export default {
  [types.SET_WORKSTATIONS_CONFIG](state, value) {
    state.area_id = value.area_id;
    state.area_image = value.area_image;
    state.area_image_alt = value.area_image_alt;
    state.area_slug = value.area_slug;
    state.workstations = value.workstations;
  },
  [types.SET_OPTIONALS](state, value) {
    state.optionals = value;
  },
  [types.SET_ACTIVE_OPTIONALS](state, value) {
    state.active_optionals.workstation_id = value.workstation_id;
    state.active_optionals.published = value.published;
    state.active_optionals.optionals = [...value.optionals];
  },
  [types.TOGGLE_ACTIVE_OPTIONALS](state, value) {
    const index = state.active_optionals.optionals.findIndex(e => e.slug === value.slug);
    if (index !== -1) {
      state.active_optionals.optionals.splice(index, 1);
    } else {
      state.active_optionals.optionals.push(value);
    }
  },
  [types.TOGGLE_PUBLISHED](state, value) {
    state.active_optionals.published = value;
  },
  [types.DELETE_ACTIVE_OPTIONALS](state) {
    state.active_optionals.workstation_id = null;
    state.active_optionals.published = null;
    state.active_optionals.optionals = [];
  }
};
