/* Mutations, actions and getters */
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    area_id: null,
    area_image: null,
    area_image_alt: null,
    area_slug: null,
    workstations: [],
    optionals: [],
    active_optionals: {
      workstation_id: null,
      published: null,
      optionals: []
    }
  },
  actions,
  mutations,
  getters
};
