import Vue from 'vue';
import VueBootstrapper from 'vue-bootstrapper';
import VueViewportManager from 'vue-viewport-manager';
import VueScrollTo from 'vue-scrollto';
import VueConfirmDialog from 'vue-confirm-dialog';
import VueDragResize from 'vue-drag-resize';
import store from '@store';

Vue.use(VueBootstrapper, {store});
Vue.use(VueViewportManager, {store});
Vue.use(VueScrollTo);
Vue.use(VueConfirmDialog);
Vue.component('vue-confirm-dialog', VueConfirmDialog.default);
Vue.component('vue-drag-resize', VueDragResize);

/* eslint-disable */
if (process.env && process.env.VUE_APP_ENV === 'development') {
  console.log('%c[VUE_APP_ENV DEVELOPMENT]', 'padding:4px;background-color:orange;color:white;');
  console.log('%c[ADDED VUE TO WINDOW]', 'padding:4px;background-color:orange;color:white;');
  console.log('%c[VUE DEVTOOLS ENABLED]', 'padding:4px;background-color:orange;color:white;');
  window.Vue = Vue;
  Vue.config.devtools = true;
}
/* eslint-enable */

export {
  Vue,
  store
};
